import React, { memo, useEffect, useState } from 'react';
import { Container, ButtonHistorico } from './styles';
import { useHistoricoProduto } from './HistoricoProdutoContext';
import { ModalHistoricoProduto } from './ModalHistoricoProduto';
import { HiDocumentText } from 'react-icons/hi';
import { FaHistory } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

interface HistoryProps {
  codProduto: number | string;
  codLoja: number | string;
  hasIcone: boolean;
}

const HistoricoProdutoContent: React.FC<HistoryProps> = (props) => {
  const { codProduto, codLoja, hasIcone } = props;
  const {
    getHistoricoProduto,
    handleFormData,
    formData,
    isOpenModal,
    onOpeningModal,
    isLoadingOpeningModal,
  } = useHistoricoProduto();

  useEffect(() => {
    if (isOpenModal) {
      handleFormData({
        ...formData,
        cod_produto: codProduto,
        cod_loja: Number(codLoja),
      });
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (isOpenModal) onOpeningModal(false);
  }, [isOpenModal, onOpeningModal]);

  return (
    <Container>
      {!hasIcone && (
        <ButtonHistorico
          className="success"
          type="button"
          disabled={isLoadingOpeningModal}
          onClick={(event) => {
            event.stopPropagation();
            getHistoricoProduto(codProduto, codLoja);
            onOpeningModal(true);
          }}
        >
          <FaHistory size={18} />
          Histórico de Produto
          {isLoadingOpeningModal && <Spinner animation="border" size="sm" />}
        </ButtonHistorico>
      )}

      {hasIcone && (
        <HiDocumentText
          style={{ cursor: 'pointer' }}
          size={35}
          onClick={(event) => {
            event.stopPropagation();
            getHistoricoProduto(codProduto, codLoja);
            onOpeningModal(true);
          }}
        />
      )}

      <ModalHistoricoProduto />
    </Container>
  );
};

const MemoContent = memo(HistoricoProdutoContent);

export default MemoContent;
